<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        新建考试
      </template>
    </HeaderBox>
    <div class="function">
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="1" tab="考试"></a-tab-pane>
        <a-tab-pane key="2" tab="模拟考试"></a-tab-pane>
      </a-tabs>
      <div>
        <a-input v-model="name" placeholder="请输入关键字" />
        <a-button type="primary btn" @click="findList">搜索</a-button>
        <a-button class="all_btn_border btn" @click="addExam">新建</a-button>
      </div>
    </div>
    <a-table
        class="table-template"
        :columns="tabIndex==1?columns:columns2"
        :data-source="tableData"
        :rowKey="item=>item.configId"
        :scroll="{x:true}"
        :loading="loading"
        @change="onPage"
        :pagination="{
          total:total,
          current:pageNumber,
          defaultPageSize:pageSize,
          showSizeChanger: true,
          showTotal: function(total, range){
          return `共${total}条`}} ">
        <template
          slot="index"
          slot-scope="item, row, index"
        >
          {{ (pageNumber - 1) * pageSize + index + 1 }}
        </template>
        <template slot="courseList" slot-scope="text">
          <p class="table_courseList" :title="text | courseList">{{ text | courseList }}</p>
        </template>
        <template slot="display" slot-scope="text">
          {{text === 0?'未上架':text == 1?'已上架':''}}
        </template>
        <template slot="isShelf" slot-scope="text">
          {{text === 0?'未上架':text == 1?'已上架':'定时上架'}}
        </template>
        <template slot="operation" slot-scope="text,record">
          <div class="btn_router_link">
            <router-link to="" @click.native="changeExam(record.configId)">修改</router-link>
            <span v-show="tabIndex == 2 && record.examNum">|</span>
            <router-link v-show="tabIndex == 2 && record.examNum" :to="'/admin/Examination/ExamineeNameList?configId=' + record.configId">考生名单</router-link>
            <!-- 考试上下架 -->
            <span v-show="tabIndex == 1 && record.display == 1">|</span>
            <router-link v-show="tabIndex == 1 && record.display == 1" @click.native="changeExamShelf(0,record.configId)" to="">下架</router-link>
            <span v-show="tabIndex == 1 && record.display != 1">|</span>
            <router-link v-show="tabIndex == 1 && record.display != 1" @click.native="changeExamShelf(1,record.configId)" to="">上架</router-link>
            <!-- 模拟考试上下架 -->
            <span v-show="tabIndex == 2 && record.isShelf == 1">|</span>
            <router-link v-show="tabIndex == 2 && record.isShelf == 1" @click.native="changeShelf(0,record.configId)" to="">下架</router-link>
            <span v-show="tabIndex == 2 && record.isShelf != 1">|</span>
            <router-link v-show="tabIndex == 2 && record.isShelf != 1" @click.native="changeShelf(1,record.configId)" to="">上架</router-link>

            <span v-if="record.isShelf != 1 && !record.examNum">|</span>
            <router-link to="" v-if="record.isShelf != 1 && !record.examNum" @click.native="delExamItem(record.configId,record.examNum)">删除</router-link>
          </div>
        </template>
      </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    width:"80px",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "考试名称",
    align:'center',
    dataIndex: "name"
  },
  {
    title: "关联课程",
    align:'center',
    width: '400px',
    dataIndex: "courseList",
    scopedSlots: { customRender: "courseList" }
  },
  {
    title: "关联试卷",
    align:'center',
    dataIndex: "paperName"
  },
  {
    title: "状态",
    align:'center',
    dataIndex: "display",
    width: '80px',
    scopedSlots: { customRender: "display" }
  },
  {
    title: "操作",
    align:'center',
    width: "160px",
    scopedSlots: { customRender: "operation" }
  }
];
const columns2 = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    width:"80px",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "考试名称",
    align:'center',
    dataIndex: "name"
  },
  {
    title: "关联课程",
    align:'center',
    width: '400px',
    dataIndex: "courseList",
    scopedSlots: { customRender: "courseList" }
  },
  {
    title: "关联试卷",
    align:'center',
    dataIndex: "paperName"
  },
  {
    title: "参考人数",
    align:'center',
    dataIndex: "examNum"
  },
  {
    title: "价格",
    align:'center',
    dataIndex: "price"
  },
  {
    title: "状态",
    align:'center',
    dataIndex: "isShelf",
    width: '80px',
    scopedSlots: { customRender: "isShelf" }
  },
  {
    title: "操作",
    align:'center',
    width: "220px",
    scopedSlots: { customRender: "operation" }
  }
];
import HeaderBox from '@/components/HeaderBox.vue'
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      name:"", // 筛选关键字
      tabIndex: 1,// tab
      columns, // 考试模板
      columns2, // 模拟考试表格模板
      tableData:[], // 列表数据
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      visible: false, // 弹框状态
      confirmLoading: false, // 按钮状态
      loading: false,
    }
  },
  // 事件处理器
  methods: {
    // 编辑
    changeExam(e){
      if(this.tabIndex == 2){
        // 跳转模考
        this.$router.push('/admin/Examination/AddMockExam?configId='+e);
      }else{
        // 跳转考试
        this.$router.push('/admin/Examination/AddExam?configId='+e);
      }
    },
    // 模拟考试上下架
    changeExamShelf(status,configId){
      this.$ajax({
        url:'/hxclass-management/exam/config/displayOrHide',
        method:'put',
        params:{
          configId:configId,
          display:status,
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.findList();
        }else{
          this.$message.error(res.message);
        }
      })
    },
    // 模拟考试上下架
    changeShelf(status,configId){
      this.$ajax({
        url:'/hxclass-management/exam/mock/config/upper/shelf?configId=' + configId + '&status=' + status,
        method:'put'
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.findList();
        }else{
          this.$message.error(res.message);
        }
      })
    },
    // 删除
    delExamItem(e,num){
      // 当模拟考试有参考人数时，不准删除
      if(this.tabIndex == 2 && num !=0 ){
        this.$message.warning('已有用户参与考试，无法删除');
        return
      }
      let _this = this
      this.$confirm({
        title: '确定删除该数据吗?',
        okText: '确认',
        onOk() {
          _this.$ajax({
            url: _this.tabIndex == 1?'/hxclass-management/exam/config/del?configId=' + e:'/hxclass-management/exam/mock/config/del?configId=' + e,
            method: "DELETE",
          }).then((res) => {
            if (res.code == 200 && res.success) {
              _this.$message.success('删除成功');
              _this.findList()
            } else {
              _this.$message.warning(res.message);
            }
          })
        },
        onCancel() {},
      });

      // this.$ajax({
      //   url:this.tabIndex == 1?'/hxclass-management/exam/config/del?configId=' + e:'/hxclass-management/exam/mock/config/del?configId=' + e,
      //   method:'delete'
      // }).then(res=>{
      //   if(res.code == 200 && res.success){
      //     this.findList();
      //   }else{
      //     this.$message.error(res.message);
      //   }
      // })
    },
    // 搜索
    findList(){
      this.pageNumber=1;
      if(this.tabIndex == 1){
        this.getExamList();
      }else if(this.tabIndex == 2){
        this.getMockExamList();
      }
    },
    // 跳转新建考试、模拟考试
    addExam(){
      if(this.tabIndex == 1){
        this.$router.push("/admin/Examination/AddExam")
      }else{
        this.$router.push("/admin/Examination/AddMockExam")
      }
    },
    // tab切换
    callback(e){
      this.tableData = [];
      this.tabIndex = e;
      this.pageNumber = 1;
      this.name = '';
      this.findList();
    },
    // 分页
    onPage(e){
      console.log(e)
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      if(this.tabIndex == 1){
        this.getExamList();
      }else if(this.tabIndex == 2){
        this.getMockExamList();
      }
    },
    // 获取考试列表
    getExamList(){
      this.loading = true
      this.$ajax({
        url:'/hxclass-management/exam/config/list',
        method:'get',
        params:{
          current:this.pageNumber,
          size:this.pageSize,
          name:this.name
        }
      }).then(res=>{
        this.loading = false
        if(res.code == 200 && res.success){
          this.total = res.data.total;
          this.tableData = res.data.records;
        }else{
          this.tableData = [];
          this.$message.error(res.message);
        }
      })
    },
    // 获取模拟考试列表
    getMockExamList(){
      this.loading = true
      this.$ajax({
        url:'/hxclass-management/exam/mock/config/list',
        method:'get',
        params:{
          current:this.pageNumber,
          size:this.pageSize,
          name:this.name
        }
      }).then(res=>{
        this.loading = false
        if(res.code == 200 && res.success){
          this.total = res.data.total;
          this.tableData = res.data.records;
        }else{
          this.tableData = [];
          this.$message.error(res.message);
        }
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.getExamList();
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
  filters: {
    courseList(e) {
      let arr = e.map(item=>{
        return item.courseName
      })
      return arr.join('， ')
    }
  }
}
</script>

<style lang="less" scoped>
  .function{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /deep/ .ant-tabs-bar{
      border-bottom: none;
    }
    .all_boder_btn{
      margin-right: 20px;
    }
    div{
      display: flex;
      .ant-input{
        width: 240px;
        margin-right: 24px;
      }
    }
  }
  .btn{
    margin-right: 24px;
  }
  /deep/.table_courseList{
    width: 400px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
  }
</style>
