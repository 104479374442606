import { render, staticRenderFns } from "./ExamSeting.vue?vue&type=template&id=f182e264&scoped=true&"
import script from "./ExamSeting.vue?vue&type=script&lang=js&"
export * from "./ExamSeting.vue?vue&type=script&lang=js&"
import style0 from "./ExamSeting.vue?vue&type=style&index=0&id=f182e264&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f182e264",
  null
  
)

export default component.exports